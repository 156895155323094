.Hero {
  display: flex;
  gap: 1rem;
  align-items: center;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  background-image: url("../../assets/images/bg.avif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hero-list {
  list-style: none;
  padding: 1rem 2rem 0;
}

.hero-list li {
  display: flex;
  gap: 1rem;
}

.hero-list li > :nth-child(1) {
  color: var(--shumba);
}

.right-h {
  position: relative;
  padding-inline: 0 2rem;
  flex: 1 1;
}

.right-h img {
  width: 350px;
}

.left-h {
  flex: 1 1;
  height: 100%;
  padding-top: 2rem;
  padding-inline: 2rem 0;
  padding-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.left-h > div:nth-child(1) {
  position: relative;
  width: max-content;
  z-index: 1;
}

.overlay_div {
  position: absolute;
  background: var(--gray);
  height: 2.8rem;
  width: 4rem;
  border-radius: 2rem;
  top: 0.2rem;
  left: 1rem;
  z-index: -1;
}

.left-h h2 {
  color: var(--darkGrey);
  text-transform: capitalize;
  font-size: 1.7rem;
  margin-bottom: -1.5rem;
  padding: 0.5rem 1.5rem;
  width: max-content;
  border: 1px solid var(--shumba);
  border-radius: 2rem;
  z-index: 1;
}
.tag {
  font-style: normal;
}
.left-h > h1 {
  font-size: 2rem;
  font-weight: bold;
  color: var(--lightgray);
  text-transform: uppercase;
  margin-block: 5rem 3rem;
  display: flex;
  flex-direction: column;
}

.left-h > p {
  line-height: 2rem;
  color: var(--lightgray);
}

.btn-hero {
  display: block;
  text-align: center;
  margin-top: 3rem;
  transition: all 500ms ease;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.btn-hero:hover {
  background: transparent;
}

@media screen and (max-width: 768px) {
  .Hero {
    margin-block: 0;
    flex-direction: column;
    align-items: center;
    padding-block: 4rem 2rem;
    background-size: fill;
    min-height: max-content;
  }

  .right-h {
    display: none;
  }

  
  .left-h {
    width: 100%;
    padding: 0 1rem;
  }

  .left-h > div:nth-child(1) {
    width: 100%;
  }

  .left-h h2 {
    margin-bottom: 0;
  }

  .left-h > h1 {
    margin-block: 2rem;
  }

  .left-h > p {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .left-h .tag {
    padding-inline-start: 0.7rem;
  }
  .overlay_div {
    top: 0.2rem;
  }
  .left-h > h1 {
    font-size: 1.5rem;
    max-width: 20ch;
  }
  .right-h {
    width: 100%;
    margin-top: 2rem;
  }

  .right-h > :nth-child(1) {
    width: 100%;
    height: 400px;
  }

  .right-h > :nth-child(2) {
    width: 100%;
    height: 400px;
    top: 0;
    left: 0;
  }

  .right-h > :nth-child(3) {
    width: 100%;
    height: 400px;
    top: 0;
    left: 0;
  }

  .right-h > :nth-child(3) > img {
    width: 100%;
    height: 400px;
  }
}

@media screen and (max-width: 375px) {
  .left-h .tag {
    padding-inline: 0.5rem;
  }
}
