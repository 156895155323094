.Contact {
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  max-width: 100vw;
}

.sidebar {
  padding: 2rem;
  border-radius: 1rem;
  background: var(--appColor);
  max-width: 25rem;
  min-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.sidebar img {
  filter: none;
  width: 10rem;
  height: 10rem;
  flex-basis: 1/4;
}

.sidebar p {
  text-align: center;
}

.sidebar-content {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
}

.contact-options {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
}

.contact-options > * {
  color: var(--shumba);
  transition: all 500ms linear;
}

.contact-options > *:hover {
  scale: 1.3;
}

.contact__form-container {
  width: max-content;
}

.contact__form-container .heading {
  margin-bottom: 3rem;
}
.contact__form-container form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 40rem;
}

form > div {
  display: flex;
  gap: 1rem;
}

input,
textarea {
  font-size: 1rem;
  width: 100%;
  padding: 1rem 0.5rem;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  background: var(--appColor);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

::placeholder {
  font-size: 1rem;
}

label {
  display: none;
}

form .btn {
  align-self: center;
}

@media screen and (max-width: 768px) {
  .Contact {
    padding: 2rem 1rem;
    flex-direction: column;
  }

  .sidebar {
    max-width: 100vw;
    width: 100%;
    min-height: max-content;
  }

  .contact__form-container {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .contact__form-container form {
    max-width: 100%;
    width: 80%;
    align-self: center;
  }

  form > div {
    flex-direction: column;
    width: 100%;
  }

  input,
  textarea {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .sidebar {
    padding: 1rem;
  }

  .contact__form-container form {
    width: 100%;
  }

  input,
  textarea {
    padding: 0.5rem;
  }
}
