.About {
  padding: 1rem 2rem 5rem 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  margin-block: 1rem;
}

.heading h1 {
  text-transform: capitalize;
}

hr {
  width: 5rem;
  height: 0.11rem;
  color: var(--shumba);
  border-radius: 20%;
  margin-top: -1rem;
}

.heading > .subheading {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  color: var(--lightgray);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.heading h1,
h2,
h3 {
  color: var(--darkGrey);
  font-style: italic;
  font-family: "Alegreya Sans", sans-serif;
}

.content {
  padding-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}

.left-c {
  flex: 1 1;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-c img {
  border-radius: 0.5rem;
  height: 100%;
  object-fit: contain;
}

.right-c {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .About {
    padding: 2rem 1rem 5rem 1rem;
  }
  .About .heading {
    margin-bottom: 2rem;
  }

  .About .heading h1 {
    font-size: 1.5rem;
  }

  .About .heading .subheading {
    font-size: 1.5rem;
    gap: 0.5rem;
  }
  .content {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }

  .left-c {
    width: 100%;
  }

  .left-c img {
    max-width: 100vw;
    width: 100%;
    height: 500px;
  }

  .right-c {
    width: 100%;
    margin: 0 0 2rem 0;
  }

  .right-c .btn {
    margin-top: 2rem;
  }
}
