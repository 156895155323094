.Reasons {
  max-width: 100vw;
  display: flex;
  gap: 2rem;
  padding: 2rem;
}

.left-r {
  flex: 1 1;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
}

.left-r > img:nth-child(1) {
  grid-row: 1/3;
  grid-column: 1/2;
  height: 500px;
  width: 100%;
  border-radius: 0.5rem 0 0 0.5rem;
}

.left-r > img:nth-child(2) {
  grid-column: 2/4;
  grid-template-rows: 1/1;
  height: 250px;
  width: 100%;
  border-radius: 0 0.5rem 0 0;
}

.left-r > img:nth-child(3),
.left-r > img:nth-child(4) {
  width: 100%;
  height: 250px;
}
.left-r > img:nth-child(4) {
  border-radius: 0 0 0.5rem 0;
}
.left-r > img {
  object-fit: cover;
}

.right-r {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.right-r > div {
  display: flex;
  gap: 1rem;
}
.right-r > div > span:nth-child(1) {
  color: var(--shumba);
  font-size: 2rem;
}

.right-r > div > span:nth-child(2) {
  color: var(--lightgray);
  font-size: 1rem;
}

.bold {
  font-weight: bold;
  text-transform: uppercase;
}

.Reasons .heading p {
  font-weight: bold;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .Reasons {
    padding-top: 0;
    padding-inline: 1rem;
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .left-r {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
  }
  .left-r > img {
    filter: none;
  }
  .left-r > img:nth-child(1) {
    grid-row: 1/4;
    grid-column: 1/2;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .left-r > img:nth-child(2) {
    grid-column: 2/3;
    grid-row: 1/2;
    height: 250px;
    width: 100%;
    border-radius: 0 0.5rem 0 0;
  }
  .left-r > img:nth-child(3),
  .left-r > img:nth-child(4) {
    width: 100%;
    height: 250px;
  }
  .left-r > img:nth-child(4) {
    border-radius: 0 0 0.5rem 0;
  }
  .right-r {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .right-r > div {
    display: flex;
    gap: 1rem;
  }
  .right-r > div > span:nth-child(1) {
    color: var(--shumba);
    font-size: 2rem;
  }
  .right-r > div > span:nth-child(2) {
    color: var(--lightgray);
    font-size: 1rem;
  }
  .bold {
    font-weight: bold;
    text-transform: uppercase;
  }
  .Reasons .heading p {
    font-weight: bold;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .left-r > img:nth-child(2),
  .left-r > img:nth-child(3),
  .left-r > img:nth-child(4) {
    height: 180px;
  }
}
