.Location {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  padding: 6rem 2rem 2rem;
  max-width: 100vw;
}

.left-l > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.left-l .heading {
  align-items: flex-start;
}

.left-l > div > span:nth-child(1) {
  color: var(--shumba);
  font-size: 2rem;
  opacity: 0.7;
  text-align: center;
}

.left-l span {
  color: var(--lightgray);
}

.left-l > div:nth-child(4) {
  align-items: flex-start;
  margin-top: 1rem;
}

.left-l > div > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.right-l .image-container img {
  border: none;
  width: 500px;
  height: 400px;
  border-radius: 0.5rem;
}

@media screen and (max-width: 768px) {
  .Location {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .Location .heading {
    margin-bottom: 2rem;
  }
  .right-l {
    width: 100%;
    height: 500px;
  }

  .right-l .image-container img {
    width: 100%;
    height: 500px;
  }
}

@media screen and (max-width: 500px) {
  .right-l {
    width: 100%;
    height: 300px;
  }
  .right-l .image-container img {
    width: 100%;
    height: 300px;
  }
}
