.ServicesPage {
  padding: 2rem;
  max-width: 100vw;
  overflow: hidden;
}

.tabs {
  display: flex;
  list-style: none;
  overflow-x: scroll;
  scrollbar-width: 0;
  max-width: 100%;
  margin: auto;
  border-radius: 0.5rem 0.5rem 0 0;
}

.tab {
  color: #134;
  padding: 1rem;
  cursor: pointer;
  background: var(--gray);
  border: 1px solid var(--darkGrey);
  font-size: 1rem;
  text-transform: uppercase;
}

.active {
  background: #134;
  color: var(--gray);
}

.subheading {
  color: var(--gray);
  padding: 2rem 0;
  text-transform: capitalize;
}

.service-content {
  margin-top: 2rem;
}

.services-container {
  margin-top: 2rem;
  position: relative;
}

.services-container .leftArrow,
.services-container .rightArrow {
  position: absolute;
  top: 1rem;
  z-index: 0;
  display: none;
  cursor: pointer;
}

.services-container .leftArrow {
  left: 1rem;
}

.services-container .rightArrow {
  right: 1rem;
}

.service-content img {
  filter: none;
  width: 15rem;
  display: block;
  margin: auto;
  object-fit: cover;
}

.service-content p {
  line-height: 2rem;
}
/* Clothing Service */

.intro {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.clothing .intro > * {
  flex: auto;
}

.intro p {
  max-width: 80ch;
}
.intro .clothing-image {
  position: relative;
}

.service-content .clothing-image img {
  position: relative;
  z-index: 3;
  width: 300px;
  height: 400px;
}

.bgColor {
  height: 380px;
  width: 150px;
  top: 3%;
  right: 20%;
  border-radius: 0.5rem;
  z-index: 0;
  position: absolute;
  background: var(--shumba);
  transform: rotate(10deg);
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

h3 {
  color: var(--shumba);
}

.list .left img {
  width: 25rem;
}

.list .right {
  max-width: 80ch;
}

.list ul {
  list-style-type: none;
  color: var(--lightgray);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.list ul > li {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.hlapo {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.hlapo p {
  max-width: 80ch;
}

/* consultation */

.intro .video-container {
  max-width: 100%;
  width: 100%;
  height: 300px;
}

.intro > * {
  flex: 1 1;
}

.intro video {
  width: 100%;
  height: 300px;
  border-radius: 1rem;
}
.intro .overlay {
  width: 100%;
  height: 300px;
  border-radius: 1rem;
}

.second__div {
  display: flex;
  gap: 2rem;
  padding-block: 2rem;
}

.second__div > * {
  flex: 1 1;
}

.outro {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-block: 3rem 4rem;
}

.outro p {
  max-width: 80ch;
}

/* markerting */
.markerting .intro {
  gap: 2rem;
}
.markerting .intro img {
  height: 300px;
  border-radius: 0.5rem;
}

.methods {
  justify-content: flex-start;
}

.markerting .methods > p {
  margin-top: 3rem;
  max-width: 80ch;
  flex: 1 1;
}

.markerting .outro img {
  flex: 1 1;
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 0.5rem;
}

/* Entertainment */

.entertainment .intro .left img {
  width: 100%;
  height: 250px;
  border-radius: 0.5rem;
}
.entertainment .outro > * {
  flex-basis: 50%;
}
.entertainment .outro .cta {
  flex-direction: column;
}

.entertainment .outro img {
  border-radius: 0.5rem;
  width: 300px;
  height: 300px;
}

.entertainment .outro .cta p,
.events .cta p {
  font-size: 1rem;
  color: var(--lightgray);
  max-width: 80ch;
}

/* Events */

.intro img {
  width: 300px;
  height: 300px;
  border-radius: 0.5rem;
}

.events > .intro {
  justify-content: center;
}

.events > .intro > img {
  max-width: 300px;
  height: 300px;
}

.events > .intro > p {
  max-width: 80ch;
}
.second__div {
  margin-bottom: 2rem;
}

.events .cta {
  flex-direction: row-reverse;
}

/* News */
.news .btn {
  margin-top: 2rem;
}

.news .intro {
  padding-top: 2rem;
}
.news .intro .right img {
  width: 500px;
}
@media screen and (max-width: 1239px) {
  .tabs {
    max-width: 90%;
  }
  .services-container .leftArrow,
  .services-container .rightArrow {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .services-container .leftArrow {
    left: 0.5rem;
  }

  .services-container .rightArrow {
    right: 0.5rem;
  }
  .list {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .services-container .leftArrow {
    left: 0;
  }

  .services-container .rightArrow {
    right: 0;
  }

  .list {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .list .left {
    margin-top: -5rem;
  }
}

@media screen and (max-width: 768px) {
  .services-container .leftArrow {
    left: -0.5rem;
  }

  .services-container .rightArrow {
    right: -0.5rem;
  }

  .clothing {
    padding: 0 2rem;
  }

  .consultation .intro {
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {
  .services-container .leftArrow {
    left: -1rem;
  }

  .services-container .rightArrow {
    right: -1rem;
  }

  .list .left img {
    width: 100%;
  }

  .intro {
    flex-direction: column;
  }

  .hlapo {
    flex-direction: column;
  }

  .second__div {
    flex-direction: column;
  }

  .outro {
    flex-direction: column;
    padding-top: 1rem;
  }

  .consultation .outro {
    flex-direction: column-reverse;
  }

  .markerting .intro img {
    width: 100%;
  }

  .markerting.outro img {
    width: 100%;
  }

  .entertainment .outro img {
    width: 100%;
  }

  .events .cta {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .news .intro .right img {
    width: 100%;
  }
}
