.video-container {
  max-width: 100vw;
  width: 100vw;
  height: 90vh;
  overflow: hidden;
}

video {
  width: 100vw;
  height: 90vh;
  object-fit: cover;
}

.Video {
  position: relative;
}

.overlay {
  width: 100vw;
  height: 90vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay:hover {
  background: none;
}

.overlay-buttons__container {
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 1.5rem;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
