.AboutPage {
  padding: 2rem;
  max-width: 100vw;
}

.first-section {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.content-f,
.video-f {
  flex-basis: 50%;
}
.content-f p {
  padding-bottom: 1rem;
}

.first-section .video-f .video-container {
  max-width: 100%;
  width: 100%;
  height: 60vh;
}

.video-f video {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.video-f .overlay {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.history {
  max-width: 100vw;
  padding-block: 2rem;
}

.history-content {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-block: 2rem;
}

.content-history,
.image-history {
  flex: 1 1;
}

.history-content img {
  filter: none;
  width: 60%;
  border-radius: 1rem;
  display: block;
  margin: auto;
  object-fit: cover;
}

.vision-and-mission {
  display: flex;
  gap: 2rem;
}

.vision-and-mission .heading {
  align-items: center;
}

.vision img,
.mission img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 1rem auto;
  filter: none;
  object-fit: cover;
}

.vision p,
.mission p {
  padding-bottom: 1rem;
}

.values {
  margin-block: 2rem;
}

.values-list {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-block: 2rem;
  list-style: none;
  transition: all 500ms linear;
}

.value {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--serviceCard);
  padding: 2rem 1rem;
  border: 2px solid var(--shumba);
  transition: all 500ms linear;
  border-radius: 1rem 0;
  width: 20rem;
  height: 22rem;
}

.value:hover {
  scale: 1.1;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  background: transparent;
}

.value h2 {
  color: var(--gray);
  text-transform: capitalize;
  padding-block: 2rem;
}

.team {
  padding-block: 2rem;
}

.team-intro {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.team-intro .heading {
  align-items: center;
}

.team-members {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: wrap;
  gap: 2rem;
  padding-block: 2rem;
  margin: auto;
}

.member {
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.socials_image {
  position: relative;
  width: 100%;
}

.member .socials {
  list-style: none;
  position: absolute;
  background: var(--serviceCard);
  padding: 1rem;
  border-radius: 0.5rem 0 0 0.5rem;
  z-index: 2;
  top: 30%;
  right: 0;
  display: none;
}

.social-link {
  text-decoration: none;
  color: var(--shumba);
  font-size: 2rem;
}

.social-link:hover {
  color: var(--gray);
}

.member img {
  width: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
  border-radius: 0.3rem;
}

.socials_image:hover .socials {
  display: block;
}

.member h6 {
  color: var(--shumba);
  font-size: 1rem;
}

.member h6 span {
  font-style: italic;
  font-size: 0.8rem;
  color: var(--gray);
}

.cta {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  align-items: center;
  justify-content: center;
}

.cta p {
  font-size: 1.5rem;
  line-height: 2rem;
  max-width: 60ch;
  color: var(--shumba);
  text-align: center;
}

@media screen and (max-width: 999px) {
  .first-section {
    flex-direction: column;
  }

  .history-content {
    flex-direction: column-reverse;
  }

  .history-content img {
    width: 80%;
  }

  .vision-and-mission {
    flex-direction: column;
  }

  .values-list {
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
  }

  .value {
    max-width: 25rem;
  }

  .value:hover {
    scale: 1;
  }

  .team-intro .heading {
    align-items: flex-start;
  }
}

@media screen and (max-width: 768px) {
  .value {
    max-width: 40rem;
  }

  .team-intro {
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {
  .cta {
    flex-direction: column;
    margin-bottom: 0;
  }
}
