.faqs {
  padding: 4rem 2rem;
  position: relative;
  max-width: 100vw;
}

.blur-faqs {
  right: 0;
  left: auto;
}
.faqs-container {
  padding: 3rem 0;
  border-radius: 5px;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  z-index: 1;
}
.faq {
  cursor: pointer;
  border-radius: 3px;
  transition: all 1s linear;
}

.question:hover,
.answer:hover {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
}
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 1rem;
  background: var(--appColor);
  padding: 1rem 2rem;
}

.answer {
  background: var(--appColor);
  padding: 1rem 2rem;
}

.question .icon {
  color: var(-shumba);
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .faqs {
    padding: 2rem 1rem;
  }
  .faqs-container {
    padding: 2rem 0;
    grid-template-columns: 1fr;
  }

  .question,
  .answer {
    padding: 1rem;
  }
}
